<template>
  <section class="message task-created">
    <section :test-id="'docketing-activity-created'">
      <MilestoneIcon :milestones="milestones" :docketing-event="metadata.next" event-name="docketing-activity.created" />
      <div>
        <h4 :test-id="'docketing-activity-title'">Docketing activity "{{ metadata.next?.name }}" created</h4>
        <div class="assignees">
          <em :test-id="'docketing-activity-tasks'"><label>Tasks(s)</label>: {{ metadata.next?.tasks?.map(t => t.name) || [] }}</em>
        </div>
        <div class="assignees">
          <em :test-id="'docketing-responsible-attorneys'"><label>Responsible Attorney(s)</label>: {{ metadata.next?.responsibleAttorneys?.filter(ra => ra) || [] }}</em>
        </div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(createdAt).toLocaleString() }}
        </div>
        <div>
          <em
            >By <label>{{ createdBy }}</label></em
          >
        </div>
      </div>
      <div>
        <!-- <hub-button variant="icon" @click="download"><hub-icon name="download"></hub-icon></hub-button> -->
      </div>
    </section>
  </section>
</template>

<script>
import MilestoneIcon from './MilestoneIcon';

export default {
  components: {
    // 'hub-button': Button,
    MilestoneIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: Date,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    },
    milestones: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    download() {}
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
